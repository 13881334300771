import React from "react";

export default class CloseAnimationBlock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {removeComponent : false};
    }

    componentDidMount() {
        setTimeout(() => {
            this._empty.style.height = "0px";
            this._empty.style.marginTop = "0px";
        }, 50);
        setTimeout(() => this.setState({removeComponent: true}), 500)

    }

    render() {

        const {customKey, height} = this.props;

        return (
            !this.state.removeComponent && <div key={customKey} ref={c => this._empty = c} style={{height: height + "px", marginTop: "20px"}} className="close-animation-block">

            </div>
        )
    }
}