import React from "react";
import {I18nMessage, Money} from "assecobs-faktor-web-common/index"
import {injectIntl} from 'react-intl';

class InvoiceAddingToast extends React.Component {

    render() {

        const {invoiceNumber, invoiceValue, invoiceCurrency} = this.props;

        return (
            <div>
                <div>
                    <div className="toast-head">
                        <I18nMessage id="fw.invoice.adding.toast.header"/>
                    </div>
                </div>

                <hr className="toast-line-info"/>
                <div className="toast-body">
                    <I18nMessage id="fw.invoice.no"/>: {invoiceNumber}<br/>
                    <I18nMessage id="fw.invoice.toast.value"/>: <Money amount={invoiceValue} currency={invoiceCurrency}/>
                </div>
            </div>
        )
    }
}

export default injectIntl(InvoiceAddingToast);
