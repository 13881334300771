import React from "react";
import {addNotification, fwUrlUtils, I18nMessage, Link, RestService} from "assecobs-faktor-web-common";
import {injectIntl} from 'react-intl';
import _ from "lodash";
import {connect} from "react-redux";

const UNSENT_STATS_URL = fwUrlUtils.getApiUrl("/webapi/unsentStats");

class BottomButtonPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {additionalButtonPanelClass: " on-scroll"};
    }

    componentDidMount = async () => {
        window.addEventListener("scroll", this.windowScroll, false);
        window.addEventListener("resize", this.windowScroll, false);
        this.windowScroll();
        this.forceUpdate();
    };

    componentWillUnmount() {
        window.removeEventListener("scroll", this.windowScroll);
        window.removeEventListener("resize", this.windowScroll);
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.clearCheckedValue, this.props.clearCheckedValue) && this.props.clearCheckedValue) {
            this.clearCheckboxValue();
        }
    }

    windowScroll = () => {
        if (!_.isNil(this._bottomPanel)) {
            let element = this._bottomPanel;
            let height = element.parentElement.offsetHeight;
            let offsetParent = element.offsetParent;
            while (element.parentElement) {
                element = element.parentElement;
                if (!_.isEqual(offsetParent, element.offsetParent)) {
                    height += element.offsetTop;
                    offsetParent = element.offsetParent;
                }
            }

            if (window.innerHeight + document.documentElement.scrollTop >= height) {
                this.setState({additionalButtonPanelClass: " on-scroll"});
            } else {
                this.setState({additionalButtonPanelClass: ""});
            }
        }
    };

    checkboxLabelClicked = () => {
        this._checkbox.checked = !this._checkbox.checked;
        this.props.onChangeChecked(this._checkbox.checked);
    };

    clearCheckboxValue = () => {
        this._checkbox.checked = false;
        this.props.onChangeChecked(false);
    };

    onClick = async () => {
        let data = await RestService.getJSON(UNSENT_STATS_URL);
        if (data.unsentPresent && data.packagesAmount >= 0) {
            this.props.dispatch(addNotification("PACKAGE_SAVE_INFO_TOAST"));
        }
    }

    render() {

        const {
            submitButtonActive, cancelLink, cancelLinkTextId, addNextField, addNextFieldTextId, handleSubmit, postRequest,
            onChangeChecked, submitButtonTextId, additionalText, OverlayComponent
        } = this.props;

        let {customButton} = this.props;

        let actionButton = submitButtonTextId ? <button disabled={!submitButtonActive}
                                                        className="k-button k-state-default btn btn-type-b btn-xs"
                                                        onClick={handleSubmit ? handleSubmit(postRequest) : postRequest}>
            <I18nMessage id={submitButtonTextId}/></button> : null;

        return (
            <div ref={(c) => this._bottomPanel = c}
                 className={"container button-wrap navbar-fixed-bottom abs-fixed-bottom" +
                 this.state.additionalButtonPanelClass + (!submitButtonActive ? " container--to-top" : "")}>
                <div className="abs-bottom-btn-panel">
                    <div>
                        {cancelLink ? <Link customClassName="k-button k-state-default btn btn-type-a btn-xs " id="link"
                                            text={this.props.intl.formatMessage({id: cancelLinkTextId})}
                                            url={cancelLink} external
                                            onClick={this.onClick}/> : <div/>}
                        {customButton && customButton}
                    </div>
                    {addNextField && <div
                        className={"abs-add-next-field " + (!submitButtonActive ? "abs-add-next-field--low-visible" : "")}>
                        <input type="checkbox"
                               className="toggle toggle-flip"
                               id="addNextField"
                               ref={c => this._checkbox = c}
                               onChange={(e) => onChangeChecked(e.target.checked)}
                               disabled={!submitButtonActive}/>
                        <label htmlFor="addNextField" className="abs-add-next-field-checkbox-opaque"/>
                        <span className="abs-add-next-label"
                              onClick={submitButtonActive ? this.checkboxLabelClicked : () => {
                                  return false;
                              }}><I18nMessage id={addNextFieldTextId}/></span>
                    </div>}
                    {additionalText && <div className="abs-add-next-field">{additionalText}</div>}
                    {OverlayComponent ?
                        React.cloneElement(OverlayComponent, {}, actionButton) :
                        actionButton
                    }
                </div>
            </div>
        )

    }

    static get defaultProps() {
        return {
            cancelLinkTextId: "common.cancel",
            submitButtonTextId: "common.save",
            addNextFieldTextId: "common.add.another"
        }
    }
}

export default connect()(injectIntl(BottomButtonPanel));