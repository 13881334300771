import React from "react";
import {I18nMessage} from "assecobs-faktor-web-common/index"
import {injectIntl} from 'react-intl';

class SendAttachmentToast extends React.Component {

    render() {

        const {
            fileName, contextNumber
        } = this.props;

        return (
            <div>
                <div>
                    <div className="toast-head">
                        <I18nMessage id="fw.attachment.adding.toast.header"/>
                    </div>
                </div>

                <hr className="toast-line-info"/>
                <div className="toast-body">
                    <I18nMessage id="fw.attachment.name"/>:
                    <ul className="fw-dropzone-toast">
                        <li> {fileName}</li>
                    </ul>
                    {contextNumber && [<I18nMessage key="message" id="fw.common.addTo"/>, ": ", contextNumber, <br key="br"/>]}
                </div>
            </div>
        )
    }
}

export default injectIntl(SendAttachmentToast);
