import React from "react";
import {connect} from "react-redux";
import {addNotification} from "assecobs-faktor-web-common";
import restService from "@assecobs/react-utils/restService";
import {injectIntl} from "react-intl";
import {REQUEST_ERROR} from "@assecobs/react-utils/common/Events";

class AjaxErrorLog extends React.Component {

    componentWillMount() {
        restService.on(REQUEST_ERROR, async (response) => {
            const data = await response.json();
            this.props.dispatch(addNotification("ERROR_TOAST", {
                bodyText: data.message || this.props.intl.formatMessage({id: "fw.error.body"})
            }))
        })
    }

    componentWillUnmount() {
        restService.off(REQUEST_ERROR);
    }

    render() {
        return null;
    }

}

export default connect()(injectIntl(AjaxErrorLog));

