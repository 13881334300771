import React from "react";
import {IndexRedirect, Route} from "react-router";
import {createRoutesFromReactChildren} from 'react-router/lib//RouteUtils';
import UserTypes from "./UserTypes";
import _ from "lodash";

export default class UserContextRoute extends React.Component {

    static createRouteFromReactElement = (element, parentRoute) => {

        const {userType, component, clientPath, contractorPath, clientRoute, contractorRoute} = element.props;

        return createRoutesFromReactChildren(
            <Route component={component}>
                <IndexRedirect to={_.isEqual(userType, UserTypes.CONTRACTOR) ? contractorPath : clientPath}/>
                {!_.isEqual(userType, UserTypes.CONTRACTOR) && clientRoute}
                {!_.isEqual(userType, UserTypes.CLIENT) && contractorRoute}
            </Route>,
            parentRoute
        )[0];
    };


};
