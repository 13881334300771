import React from "react";
import PropTypes from "prop-types";
import {ABSPieChart} from "@assecobs/react-charts/index";
import InvoiceChartTooltip from "./InvoiceChartTooltip";
import InvoiceChartLegend from "./InvoiceChartLegend";
import _ from "lodash";
import {CHART_COLORS} from "assecobs-faktor-web-profile/ChartColors";

export default class InvoicePieChart extends React.Component {

    shouldComponentUpdate(nextProps) {
        return !_.isEqual(nextProps.data, this.props.data);
    }

    render() {
        const {data} = this.props;
        const colors = CHART_COLORS.slice(-data.length);

        return (
            <ABSPieChart data={data} colors={colors} nameKey="name" valueKey="amount" size={110} tooltip={InvoiceChartTooltip} legend={InvoiceChartLegend} innerRadius="47%" />
        );
    }
}

InvoicePieChart.propTypes = {
    data: PropTypes.array,
    colors: PropTypes.array
};
