import React from "react";
import PropTypes from "prop-types";
import {fwUrlUtils, RestService} from "assecobs-faktor-web-common";
import _ from "lodash";
import SingleSelect from '@assecobs-react-components/single-select/src/SingleSelect';
import {FormattedMessage} from 'react-intl'
import ControlTemplate from "./ControlTemplate";
import ItemTemplate from "./ItemTemplate";
import {isNullOrEmpty} from '@assecobs-js-utils/is-null-or-empty/src/isNullOrEmpty';

export default class ClientSelectItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dropdownPlaceholder: null,
            dataSource: [],
            clients: null,
            currentClientId: null,
            expandedClientId: null,
            forceOpen: false,
            noAgreementsDescriptionExpanded: false,
            isLoading: false
        }
    }

    componentDidMount = async () => {

        this.setState({
            isLoading: true
        }, async () => {
            const URL_RELATED_CLIENTS = fwUrlUtils.getApiUrl("/webapi/relatedClients");
            const data = await RestService.getJSON(URL_RELATED_CLIENTS);
            const userContext = this.context.userContext;
            this.setState({
                clients: data,
                currentClientId: userContext.get("clientId")
            });
            this.setDataSource();
            this.setDropdownPlaceholder();

            this.setState({
                isLoading: false
            });
        });
    }

    onChangeValue = async (value) => {
        const URL_CHOOSE_CLIENTS = fwUrlUtils.getApiUrl("/webapi/chooseClient");
        this.setState({expandedClientId: null, currentClientId: value});
        const response = await RestService.postJSON(URL_CHOOSE_CLIENTS, {clientId: value});
        window.location.href = fwUrlUtils.getContextUrl(response.redirectUrl);
    }

    setDropdownPlaceholder = () => {
        const arrayDropdownPlaceholders = !_.isNil(this.state.clients) ? this.state.clients.map(this.getClientName) : "";
        const dropdownPlaceholder = arrayDropdownPlaceholders.find(el => !isNullOrEmpty(el));
        this.setState({dropdownPlaceholder});
    }

    getClientName = (client) => {
        if (_.isEqual(client.id, this.state.currentClientId))
            return client.name;
    }

    setDataSource = () => {
        const ds = this.state.clients.map(client => ({value: client.id, label: client.name, hasAgreements: client.hasAgreements}));
        this.setState({dataSource: ds});
    }

    render() {
        const getStateAndHelpers = () => ({
            dataSource: this.state.dataSource,
            isLoading: this.state.isLoading,
            emptyOptionLabel: <FormattedMessage id={'ihd3.noValue'}/>,
            itemContentWidth: 230,
            placeholder: this.state.dropdownPlaceholder,
            onChangeValue: this.onChangeValue,
            value: this.state.currentClientId,
            itemsNumberToShowSearch: 100,
            controlTemplate: ControlTemplate,
            className: "client-select-option-placeholder",
            itemTemplate: ItemTemplate,
            listClass: "client-select-dropdown"
        })

        return (
            <SingleSelect {...getStateAndHelpers()} />
        )
    }

    static contextTypes = {
        userContext: PropTypes.any
    }
}
