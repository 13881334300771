import {ERROR_REASON} from "../../../../../../../../../packages/src/main/resources/static/scripts/app/components/constants/errorReason"
import {addNotification} from "assecobs-faktor-web-common";
import _ from "lodash"

const showErrorToast = ({rejectedFiles, rejectedFilesFromResponse, maxFileSize, dispatch}) => {
    const filesWithErrorTypeOrSize = rejectedFiles.filter(file => file.error === ERROR_REASON.TYPE || file.error === ERROR_REASON.SIZE);
    const AllFilesWithErrorTypeOrSize = filesWithErrorTypeOrSize.map(file => file.data.name).concat(rejectedFilesFromResponse);
    const filesWithErrorSumSize = rejectedFiles.filter(file => file.error === ERROR_REASON.EXCEEDED_FILES_SIZE);

    if (!_.isEmpty(filesWithErrorSumSize)) {
        dispatch(addNotification("SEND_ATTACHMENT_ERROR_TOAST", {maxFileSize}));
    }
    if (!_.isEmpty(AllFilesWithErrorTypeOrSize)) {
        dispatch(addNotification("SEND_ATTACHMENTS_ERROR_TOAST", {files: AllFilesWithErrorTypeOrSize}));
    }
};

export {showErrorToast}