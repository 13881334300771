import React from "react";
import {I18nMessage} from "assecobs-faktor-web-common/index"
import {injectIntl} from 'react-intl';

class SendAttachmentErrorToast extends React.Component {

    render() {

        const {
            maxFileSize
        } = this.props;

        return (
            <div>
                <div>
                    <div className="toast-head">
                        <I18nMessage id="fw.attachment.adding.error.toast.header"/>
                    </div>
                </div>

                <hr className="toast-line-info"/>
                <div className="toast-body">
                    <I18nMessage id="fw.attachment.adding.maxBunchFilesSize" values={{
                        maxFileSize: maxFileSize
                    }}/>
                </div>
            </div>
        )
    }
}

export default injectIntl(SendAttachmentErrorToast);
