import React from "react";
import {connect} from "react-redux";
import Toast from "./Toast";
import CloseAnimationBlock from "./CloseAnimationBlock";
import {removeNotification} from "./actions";
import _ from "lodash";

const toastKey = Symbol();

class NotificationSystem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {toasts: []};
        this[toastKey] = 0;

    }

    componentDidMount() {
        const stringifyToasts = window.sessionStorage.getItem("toasts");
        if (!_.isNil(stringifyToasts)) {
            const toasts = JSON.parse(stringifyToasts);
            toasts.forEach(toast => {
                    this.addNotification(
                        this.props.toasts[toast.toastName](!_.isEqual(toast.toastParams, "undefined") ? toast.toastParams : {})
                    );
                    this.props.dispatch(removeNotification());
                }
            );
            this.removeNotificationFromSessionStorage();
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isNil(this.props.toast) && (!_.isEqual(prevProps.toast, this.props.toast) || !_.isEqual(prevProps.toastUuid, this.props.toastUuid))) {
            this.addNotification(this.props.toasts[this.props.toast](!_.isNil(this.props.toastParams) ? this.props.toastParams : {}));
        }
    }

    addNotification = (toast) => {
        if (_.isNil(toast.dismissTimeout)) {
            toast.dismissTimeout = 2000;
        }
        if (!toast.closeCrossEnabled) {
            toast.closeCrossEnabled = true;
        }
        this.state.toasts.push({
            key: this[toastKey],
            component: <Toast key={this[toastKey]} customKey={this[toastKey]} type={toast.type} content={toast.content}
                              dismissTimeout={toast.dismissTimeout} closeCrossEnabled={toast.closeCrossEnabled}
                              removeNotification={this.removeNotification}/>
        });
        this.forceUpdate();
        this[toastKey]++;
    };

    removeNotificationFromSessionStorage = () => {
        window.sessionStorage.removeItem("toasts");
    };

    removeNotification = (key, height) => {
        let selectedIndex = null;
        this.state.toasts.forEach((toast, index) => {
            if (_.isEqual(toast.key, key)) {
                selectedIndex = index;
            }
        });
        this.state.toasts.splice(selectedIndex, 1, {
            key: key,
            component: <CloseAnimationBlock key={key} customKey={key} height={height}/>
        });
        this.forceUpdate();
        this.props.dispatch(removeNotification());
    };

    render() {
        return (
            <div className="toast-flex-container" ref={(container) => this._container = container}>
                {this.state.toasts.map(toast => toast.component)}
            </div>
        );
    }
}

export default connect(({notifications}) => ({
    toast: notifications.toast,
    toastParams: notifications.toastParams,
    toastUuid: notifications.toastUuid
}))(NotificationSystem);

//toast options: content, type, dismissTimeout, closeCrossEnabled
