import React from "react";
import {I18nMessage} from "assecobs-faktor-web-common/index"

export default class CommonToast extends React.Component {

    render() {

        const {headerTextKey, bodyText} = this.props;

        return (
            <div>
                <div>
                    <div className="toast-head">
                        <I18nMessage id={headerTextKey}/>
                    </div>
                </div>

                {bodyText && [<hr className="toast-line-info" key="hr_line"/>,
                    <div key="toast_body" className="toast-body">
                        {bodyText}
                    </div>]}

            </div>
        )
    }
}
