import React from "react";
import {I18nMessage} from "assecobs-faktor-web-common/index"
import {injectIntl} from 'react-intl';
import {Config} from "assecobs-faktor-web-common/index";

class SendAttachmentsErrorToast extends React.Component {

    constructor(props) {
        super(props);
        this.state = {show: false};
    }

    changeShow = () => {
        this.setState({show: true});
    };

    render() {

        const {
            files
        } = this.props;

        return (
            <div>
                <div>
                    <div className="toast-head">
                        <I18nMessage id="fw.attachment.adding.error.toast.header"/>
                    </div>
                </div>

                <hr className="toast-line-info"/>
                <div className="toast-body">
                    <div>
                        <b><I18nMessage id="fw.attachment.adding.invalidType.sizeFile"/>:</b>
                        <ul className="fw-dropzone-toast fw-dropzone-toast-ul">
                            {files.map((item, key) => (
                                    <li key={key}><span className="fw-dropzone-toast-li"> {item}</span></li>
                                )
                            )}
                        </ul>

                        {!this.state.show && <a onClick={this.changeShow}>
                            <I18nMessage id="fw.attachment.adding.error.toast.additional.information.1"/>
                        </a>}

                        {this.state.show && <div>
                            <I18nMessage id="fw.attachment.adding.error.toast.additional.information.2"/>:
                            <ul className="fw-dropzone-toast fw-dropzone-toast-ul">
                                <li><span className="fw-dropzone-toast-li">
                                    pdf, doc, docx, xls, xlsx, bmp, jpg, jpeg, gif, png, tiff, tif, csv: <b><I18nMessage
                                    id="fw.attachment.adding.error.toast.additional.information.4"/></b>
                                </span></li>
                                <li><span className="fw-dropzone-toast-li">
                                    zip: <b><I18nMessage id="fw.attachment.adding.error.toast.additional.information.5"/></b>
                                </span></li>
                            </ul>
                            <span><I18nMessage id="fw.attachment.adding.error.toast.additional.information.3"/> <b>{Config.getMaxRequestSize()}<I18nMessage
                                id="fw.attachment.adding.error.toast.additional.information.6"/></b></span>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(SendAttachmentsErrorToast);
