import React from "react";
import {I18nMessage, Link} from "assecobs-faktor-web-common/index"
import {injectIntl} from 'react-intl';

class RequestSentToast extends React.Component {

    render() {

        const {requestTypeKey} = this.props;

        return (
            <div>
                <div>
                    <div className="toast-head">
                        <I18nMessage id="fw.request.message.sent"/>
                    </div>
                </div>

                <hr className="toast-line-info"/>
                <div className="toast-body">
                    <I18nMessage id="fw.request.message.type"/>: <I18nMessage id={requestTypeKey} /><br/>
                </div>
            </div>
        )
    }
}

export default injectIntl(RequestSentToast);
