import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Button, Modal} from "react-bootstrap";
import {addNotification, fwUrlUtils, I18nMessage} from "assecobs-faktor-web-common";
import {reduxForm} from "redux-form";
import {injectIntl} from 'react-intl';
import {validators} from "assecobs-faktor-web-common/forms";
import _ from "lodash";
import RestService, {HTTP_GONE} from "assecobs-faktor-web-common/rest/RestService"

import FileRequestPromptHeader from "./FileRequestPromptHeader";
import FileRequestPromptBodyFull from "./FileRequestPromptBodyFull";
import {isVisible} from "assecobs-faktor-web-common/instanceConfigMetaData/instanceConfigMetaDataHelpers";

const GET_FILE_URL = fwUrlUtils.getApiUrl("/webapi/fileRequest/get");
const SEND_FILE_URL = fwUrlUtils.getApiUrl("/webapi/fileRequest/send");

const emailInput = Symbol();

class FileRequestPrompt extends React.Component {

    constructor(props) {
        super(props);
        this[emailInput] = "";
        this.state = {emailBlockLoading: false, errorMessage: null};
    }

    getFile = async () => {

        let params = "?";

        Object.keys(this.props.fileOptions).forEach((key, index) => {

            params += key + "=" + this.props.fileOptions[key];

            if (!_.isEqual(index, Object.keys(this.props.fileOptions).length - 1)) {
                params += "&";
            }

        });

        //Checking if the file is current and the response has postAction section
        const response = await RestService.get(GET_FILE_URL, this.props.fileOptions);
        if (response.status !== HTTP_GONE) {
            await window.open(GET_FILE_URL + params, '_blank');
        }

        this.props.close();
    };

    sendFile = async () => {
        const errorMessage = validators.emailFileRequest(this[emailInput])

        if (errorMessage) {
            this.setState({errorMessage})
            return
        }

        const params = {
            ...this.props.fileOptions, mail: this[emailInput]
        };

        await this.setState({emailBlockLoading: true});

        await RestService.post(SEND_FILE_URL, params).then(response => {
            if (response.ok) {

                this[emailInput] = "";
                this.props.close();
                this.setState({errorMessage: null})
                this.props.dispatch(addNotification("INFO_TOAST", {
                    headerTextKey: "fw.fileRequestPrompt.toast.info.sent"
                }));

            } else {
                this.props.dispatch(addNotification("ERROR_TOAST"));
            }
        });

        await this.setState({emailBlockLoading: false});

    };

    emailOnChange = (value) => {
        this[emailInput] = value;
    };

    closeModal = value => {
        this.setState({errorMessage: null})
        this[emailInput] = ''
        this.props.close(value)
    }

    render() {
        const {show, handleSubmit} = this.props;
        const isVisibleSendFile = isVisible({module: null, window: null, component: 'send.mail', element: null});
        const isClassName = isVisibleSendFile ? 'file-request-modal' : 'file-request-modal-no-mail';

        return (<Modal className={`${isClassName}`} show={show} onHide={this.closeModal} animation={false} autoFocus={true}
                       keyboard={true} backdrop={true}>
            <Modal.Header>
                <FileRequestPromptHeader close={this.closeModal}/>
            </Modal.Header>
            <Modal.Body>
                <FileRequestPromptBodyFull handleSubmit={handleSubmit} emailOnChange={this.emailOnChange}
                                           getFile={this.getFile} sendFile={this.sendFile}
                                           loading={this.state.emailBlockLoading}
                                           errorMessage={this.state.errorMessage}/>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-type-a btn-xs" onClick={this.closeModal}><I18nMessage
                    id="common.closeWindow"/></Button>
            </Modal.Footer>
        </Modal>)
    }

    static get propTypes() {
        return {
            show: PropTypes.bool, close: PropTypes.func, fileOptions: PropTypes.object
        }
    }
}

const form = reduxForm({
    form: "FileRequestPrompt"
});

export default connect()(form(injectIntl(FileRequestPrompt)));
