import React from "react";
import {I18nMessage} from "assecobs-faktor-web-common/index";
import {injectIntl} from "react-intl";

class SettingSentToast extends React.Component {

    render() {

        const {header, templateInvoiceName} = this.props;

        return (
            <div>
                <div>
                    <div className="toast-head">
                        <I18nMessage id={header}/>
                    </div>
                </div>

                <hr className="toast-line-info"/>
                <div className="toast-body">
                    <strong><I18nMessage id="fw.setting.number"/>:</strong> {templateInvoiceName}
                </div>
            </div>
        )
    }
}

export default injectIntl(SettingSentToast);
