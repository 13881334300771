import { combineReducers } from 'redux';
import {routerReducer} from 'react-router-redux';

import prompt from "../prompt/reducer";
import invoices from "../quickSearch/searchReducer";
import notifications from "../notificationSystem/reducer";
import alerts from "../alerts/reducer";

export default function customCombineReducers(componentReducers) {


    let reducers = {

        ...componentReducers,
        prompt,
        invoices,
        notifications,
        alerts,
        routing: routerReducer
    };

    return combineReducers(reducers);
}
