import React from "react";
import {I18nMessage, Link} from "assecobs-faktor-web-common/index"
import {injectIntl} from 'react-intl';
import _ from "lodash";

const SEND = 1;

class PackageSaveToast extends React.Component {

    render() {

        const {packageNumber, packageLink, typeOperation} = this.props;

        return (
            <div>
                <div>
                    <div className="toast-head">
                        <I18nMessage id={_.isEqual(typeOperation, SEND) ? "fw.import.packageSend.toast.header" : "fw.import.packageSave.toast.header"}/>
                    </div>
                </div>

                <hr className="toast-line-info"/>
                <div className="toast-body">
                    <I18nMessage id="fw.package.no"/>: {packageNumber}<br/>
                </div>
                <div className="toast-footer">
                    <Link id="packageDetails"
                          url={packageLink}
                          text={this.props.intl.formatMessage({id: "fw.import.package.toast.linkText"})}
                          external/>
                </div>
            </div>
        )
    }
}

export default injectIntl(PackageSaveToast);
