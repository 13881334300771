import React from "react";
import {I18nMessage, Link, fwUrlUtils} from "assecobs-faktor-web-common/index"

export default (props) => {
    return (
        <div>
            <div>
                <div className="toast-head">
                    <I18nMessage id="fw.orderReport.notification.success"/>
                </div>
            </div>

            <hr className="toast-line-info"/>
            <div className="toast-body">
                <span><strong><I18nMessage
                    id="fw.orderReport.notification.reportType"/>:</strong> {props.reportType}</span><br/>
                {props.baseAgreement &&
                <span><strong><I18nMessage
                    id="fw.agreement"/>:</strong> <Link
                    url={fwUrlUtils.getApiUrl("/agreements/details?baseAgreementAnnex0Id=") + props.baseAgreement.baseAgreementAnnex0Id}
                    text={props.baseAgreement.actualAgreementNo}
                    external/></span>}<br/>
                {props.contractors && props.contractors.length > 0 &&
                <div>
                    <strong><I18nMessage
                        id="fw.contractor"/>:</strong> {props.contractors.map((contractor, index, contractors) =>
                        <span><Link id={contractor.contractorId}
                                    text={contractor.shortName}
                                    url={fwUrlUtils.getApiUrl("/contractors/details?baseAgreementAnnex0Id=") +
                                    props.baseAgreement.baseAgreementAnnex0Id + "&contractorClientId=" + contractor.contractorClientId}
                                    external/>{index < contractors.length - 1 && ", "}</span>)}
                    <br/>
                </div>}
                <span><strong><I18nMessage id="fw.requestedReports.reportFormat"/>:</strong> {props.fileExtension.fileExtensionDesc}</span><br/>
                {props.cyclicityType &&
                <span><strong><I18nMessage id="fw.cyclicity"/>:</strong> {props.cyclicityType}</span>}
            </div>
        </div>

    )
}
