import React from "react";
import {I18nMessage, Money} from "assecobs-faktor-web-common/index"

export default (props) => {
    return (
        <div>
            <div>
                <div className="toast-head">
                    <I18nMessage id="fw.invoice.editing.toast.header"/>
                </div>
            </div>

            <hr className="toast-line-info"/>
            <div className="toast-body">
                <I18nMessage id="fw.invoice.no"/>: {props.invoiceNumber}<br/>
                <I18nMessage id="fw.invoice.toast.value"/>: <Money amount={props.invoiceValue} currency={props.invoiceCurrency}/>
            </div>
        </div>
    )
}
