import * as actionTypes from "./actionTypes";

const initState = {
    toast: null,
    toastParams: null,
    toastUuid: null
};

export default function (state = initState, action) {

    switch (action.type) {
        case actionTypes.ADD_NOTIFICATION:
            return {
                ...state,
                toast: action.toast,
                toastParams: action.toastParams,
                toastUuid: Date.now()
            };
            break;

        case actionTypes.REMOVE_NOTIFICATION:
            return {
                ...state,
                toast: null,
                toastParams: null,
                toastUuid: null
            };
            break;
    }

    return state;
}
