import React from "react";
import {connect} from "react-redux";
import DropZoneComponent from "./DropZoneComponent";
import {addNotification, DocumentType} from "../index";
import {sendAttachment, sendAttachments} from "./actions";
import _ from "lodash";
import {hideLoader, showLoader} from "assecobs-faktor-web-common/loaders/actions/index";
import {Config} from "assecobs-faktor-web-common/index";

const dropZone = Symbol();
const contextNumber = Symbol();
const elementId = Symbol();
const _packageId = Symbol();
const _documentTypeId = Symbol();
const _showLoader = Symbol();
const _isExeededFilesSize = Symbol();
const _isSingleFile = Symbol();

class InvisibleDropZoneComponent extends React.PureComponent {

    constructor(props) {
        super(props);
        this[dropZone] = null;
        this[contextNumber] = null;
        this[elementId] = null;
        this[_packageId] = null;
        this[_documentTypeId] = null;
        this[_showLoader] = false;
        this[_isExeededFilesSize] = null;
        this[_isSingleFile] = false;

        if (props.isSingleFile) {
            this[_isSingleFile] = true;
        }
    }

    sendAttachment = async (file, fileExtensionId) => {
        const formData = new FormData();

        formData.append("FileData", file);
        formData.append("FileName", file.name);
        formData.append("FileSize", file.size);

        formData.append("CommonFileExtensionId", fileExtensionId);

        formData.append("DocumentTypeId", this[_documentTypeId] || DocumentType.DOCUMENT_TYPE_FOR_PACKAGE);
        formData.append("EntityElementId", this[elementId]);

        await this.props.dispatch(sendAttachment(formData)).then((response) => {
            if (!response.redirected) {
                this.props.dispatch(addNotification("SEND_ATTACHMENT_TOAST", {
                    fileName: file.name,
                    contextNumber: this[contextNumber]
                }));
            }
        });
    };

    sendAttachments = async (files, filesExtensionId) => {
        const filesToUpload = [];

        if (files !== null && this[_showLoader] === true) {
            this.props.dispatch(showLoader());
        }

        const formData = new FormData();
        const documentTypeId = this[_documentTypeId] || DocumentType.DOCUMENT_TYPE_FOR_PACKAGE;
        const packId = this[_packageId] || this.props.elementId;
        const entityElementId = this[elementId];

        if (!_.isNil(files)) {

            for (let i = 0; i < files.length; i++) {
                if (!_.isNil(files[i]) && !files[i].INVALID_FILE) {
                    filesToUpload.push(files[i]);

                    formData.append("FileData", files[i]);

                    if (!_.isNil(filesExtensionId[i])) {
                        formData.append("CommonFileExtensionId", filesExtensionId[i]);
                    }

                    formData.append("FileName", files[i].name);
                    formData.append("FileSize", files[i].size);
                    formData.append("EntityElementId", entityElementId);
                    formData.append("DocumentTypeId", documentTypeId);

                    if (!_.isNil(packId)) {
                        formData.append("PackageId", packId);
                    }
                }
            }

            if (!_.isEqual(filesToUpload.length, 0)) {
                await this.props.dispatch(sendAttachments(formData)).then(async (response) => {
                    this.props.dispatch(hideLoader());

                    if (response.ok) {
                        let responseJSON = await response.json();

                        if (responseJSON.UploadSuccess) {
                            this.props.dispatch(addNotification("SEND_ATTACHMENTS_TOAST", {
                                filesName: responseJSON.UploadSuccess,
                                contextNumber: responseJSON.EntityElementDescription ? responseJSON.EntityElementDescription : this[contextNumber]
                            }));

                            this.setToastWithFileTypeError(files, filesToUpload);
                        }

                        if (responseJSON.UploadFailure) {
                            this.props.dispatch(addNotification("SEND_ATTACHMENTS_ERROR_TOAST", {
                                files: responseJSON.UploadFailure
                            }));
                        }

                        this.showTooltipWithExceededFilesInformation(files, filesToUpload);

                        return;
                    }
                });
            }

            if (files.length > 0 && filesToUpload.length === 0) {
                this.showTooltipWithExceededFilesInformation(files, filesToUpload);
                this.setToastWithFileTypeError(files, filesToUpload);
            }
        }
    };

    setToastWithFileTypeError = (files, filesToUpload) => {
        this.props.dispatch(hideLoader());

        if (files === filesToUpload) {
            return null;
        }

        const wrongFilesName = files.filter(file => {
                return Object.hasOwnProperty.call(file, 'ERROR_REASON') && ['ERROR_REASON_TYPE', 'ERROR_REASON_EXCEEDED_FILES_SIZE'].includes(file.ERROR_REASON);
            }
        ).map(errorFile => errorFile.name);

        if (wrongFilesName.length > 0) {
            this.props.dispatch(addNotification("SEND_ATTACHMENTS_ERROR_TOAST", {files: wrongFilesName}));
        }
    };

    showTooltipWithExceededFilesInformation = (files, filesToUpload) => {
        if (files === filesToUpload) {
            return null;
        }

        const exceededFile = files.filter(file => {
                return Object.hasOwnProperty.call(file, 'ERROR_REASON') && file.ERROR_REASON === 'ERROR_REASON_EXCEEDED_FILES_SIZE';
            }
        );

        if (exceededFile.length > 0) {
            this.props.dispatch(addNotification("SEND_ATTACHMENT_ERROR_TOAST", {maxFileSize: Config.getMaxRequestSize()}));
        }
    };

    showErrorFileUploadToast = (fileTypeRejected, params) => {
        if (fileTypeRejected) {
            this.props.dispatch(addNotification("INVALID_ATTACHMENT_TYPE_TOAST", params));
        } else {
            this.props.dispatch(addNotification("INVALID_ATTACHMENT_SIZE_TOAST", params));
        }
    };

    openFileWindow = (contextNum, elemId, docTypeId, packageId = void 0, showLoader = false) => {
        this[contextNumber] = contextNum;
        this[elementId] = elemId;
        this[_packageId] = packageId;
        this[_documentTypeId] = docTypeId;
        this[dropZone].getWrappedInstance().clearFiles();
        this[dropZone].getWrappedInstance().openFileWindow();
        this[_showLoader] = showLoader;
    };

    onExceededFilesSize = (maxFileSize) => {
        this[_isExeededFilesSize] = maxFileSize;
        //this.props.dispatch(addNotification("SEND_ATTACHMENT_ERROR_TOAST", {maxFileSize}));
    };

    render() {

        return (
            <React.Fragment>
                <div style={{display: "none"}}>
                    <DropZoneComponent ref={c => this[dropZone] = c} onlyFileWindow={true}
                                       filesOnChange={this.sendAttachments}
                                       multiple={!this[_isSingleFile]}
                                       showErrorFileUploadToast={this.showErrorFileUploadToast}
                                       onExceededFilesSize={this.onExceededFilesSize} />
                </div>
            </React.Fragment>
        )
    }
}

export default connect(null, null, null, {withRef: true})(InvisibleDropZoneComponent)
