import React from "react";
import {Tooltip} from "react-bootstrap";
import {Overlay} from "react-overlays";
import {addNotification, Button, fwUrlUtils, I18nMessage, RestService} from "assecobs-faktor-web-common";
import _ from "lodash";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";

const UNSENT_STATS_URL = fwUrlUtils.getApiUrl("/webapi/unsentStats");
const unsentData = Symbol();

class SendButtonOverlay extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showTooltip: false,
            overlayHeader: ""
        };

        this[unsentData] = null;
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.checkUnsent && !_.isEqual(this.props.checkUnsent, prevProps.checkUnsent)) {
            await this.showTooltip();
        }
    };

    showTooltip = async () => {

        let data = await RestService.getJSON(UNSENT_STATS_URL);

        if (data.unsentPresent) {
            if (data.invoicesAmount <= 0 && data.packagesAmount >= 0) {
                if (this.props.showTooltip !== false) {
                    this.props.dispatch(addNotification("PACKAGE_SAVE_INFO_TOAST", {}, this.props.isTooltipRedirect));
                }
                window.location.href = data.proceedLink;
            } else {
                this[unsentData] = data;
                this.setState({showTooltip: true, overlayHeader: this[unsentData].overlayHeader});
            }
        } else {
            window.location.href = data.proceedLink;
        }

    };


    closeTooltip = () => {
        this.setState({showTooltip: false});
    };

    goToSending = () => {
        if (this.props.selectedBaseAgreementId)
            window.location.href = this[unsentData].proceedLink + "?baseAgreementAnnex0Id=" + this.props.selectedBaseAgreementId;
        else
            window.location.href = this[unsentData].proceedLink;
    };

    continueSending = () => {
        if (this[unsentData].invoicesAmount <= 0 && this[unsentData].packagesAmount >= 0) {
            window.location.href = this[unsentData].proceedLink;
        } else {
            this.props.continueSending();
            this.closeTooltip();
        }
    };

    end = () => {
        if (this.props.invoiceContext) {
            window.location.href = this.props.endLink || this[unsentData].invoiceListLink;
        }
        if (this.props.packageContext) {
            window.location.href = this.props.endLink || this[unsentData].packageListLink;
        }
    };

    render() {

        const {proceedWithSending} = this.props;

        const tooltip = <Tooltip id="tooltip-top">
            <div className="fw-text-block">{this.state.overlayHeader}</div>
            <div className="fw-button-block">
                <Button styleType="A" text={<I18nMessage id="fw.unsent.bottomPanel.end"/>} onClick={this.end}/>
                <Button styleType="B" text={proceedWithSending ? <I18nMessage id="fw.unsent.bottomPanel.proceed"/> :
                    <I18nMessage id="fw.unsent.bottomPanel.continue"/>}
                        onClick={proceedWithSending ? this.goToSending : this.continueSending}/>
            </div>
        </Tooltip>;

        return (
            <div ref={(c) => this._container = c} onClick={(event) => {
                event.nativeEvent.stopImmediatePropagation();
            }} className="fw-unsent-block">
                {this.state.showTooltip && <div className="modal-backdrop in"/>}
                <Overlay show={this.state.showTooltip}
                         placement="top"
                         container={this._container}
                         target={this._selectComponent}>
                    {tooltip}
                </Overlay>
                <div ref={c => this._selectComponent = c}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}
export default connect()(injectIntl(SendButtonOverlay));