import React from 'react';
import PropTypes from 'prop-types';
import {Waypoint} from "react-waypoint";
import {useList} from "./hooks/useList"
import {v4 as uuidv4} from "uuid"

type DsItem = { value: string | number, label: string | number, groupKey: string | number }
type DataSource = Array<DsItem>

interface InfiniteScrollListProps {
    itemTemplate: () => React.ReactElement,
    dataSource: DataSource,
    initialNumberOfElements: number,
    stepNumberOfElements: number,
    classNameItemTemplate: string
}

const InfiniteScrollList = ({
                                itemTemplate: ItemTemplate,
                                dataSource = [],
                                initialNumberOfElements = 20,
                                stepNumberOfElements = 10,
                                classNameItemTemplate = ''
                            }: InfiniteScrollListProps): React.ReactElement => {

    const {itemToShow, loadMoreItems} = useList({dataSource, initialNumberOfElements, stepNumberOfElements})

    const getPropsAndHelpers = (item: DsItem) => ({item, key: uuidv4()})

    return (
        <>
            <div className={classNameItemTemplate}>
                {itemToShow.map(item => <ItemTemplate {...getPropsAndHelpers(item)}/>)}
                <Waypoint onEnter={loadMoreItems}/>
            </div>
        </>
    );
};

InfiniteScrollList.propTypes = {
    itemTemplate: PropTypes.func.isRequired,
    dataSource: PropTypes.array,
    initialNumberOfElements: PropTypes.number,
    stepNumberOfElements: PropTypes.number,
    classNameItemTemplate: PropTypes.string
};

export default InfiniteScrollList;