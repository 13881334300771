import React from "react";
import {Link} from "assecobs-faktor-web-common/index";
import shortid from "shortid";
import _ from "lodash";
import {isNullOrEmpty} from '@assecobs-js-utils/is-null-or-empty/src/isNullOrEmpty';

const _replaceTag = Symbol();

export default class ParsableText extends React.PureComponent {

    [_replaceTag] = (source, toReplace, replacer) => {
        const index = _.indexOf(source, toReplace);
        source.splice(index, 1, replacer);
    };

    render() {
        const {text, metadata} = this.props;

        let textParts = text.split(/(\^[^\^]*\^)/);

        metadata.forEach((p) => {
            const id = shortid.generate();
            let replacer;

            const url = isNullOrEmpty(p.url) ? p.get("url") : p.url;
            const value = isNullOrEmpty(p.value) ? p.get("value") : p.value;
            const tag = isNullOrEmpty(p.tag) ? p.get("tag") : p.tag;

            if (!isNullOrEmpty(url)) {
                replacer = (
                    <Link key={`parsableText-${id}`}
                          id={`parsableTextLink-${id}`}
                          text={value}
                          url={url}
                          external
                    />
                )
            } else {
                replacer = value
            }

            this[_replaceTag](textParts, "^" + tag + "^", replacer);
        });

        return (
            <span>{textParts}</span>
        )
    }
}
