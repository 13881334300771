import React from "react";
import {I18nMessage} from "assecobs-faktor-web-common/index";
import _ from "lodash";

export default (props) => {

    const invoiceNumber = [<I18nMessage id="fw.invoice.no"/>, ": ", props.invoiceNumber];
    const invoicesAmount = [<I18nMessage id="fw.common.invoiceAmount"/>, ": ", props.invoicesAmount];

    return (
        <div>
            <div>
                <div className="toast-head">
                    <I18nMessage
                        id={!_.isNil(props.invoiceNumber) ? "fw.invoice.confirmation.changeStatus" : "fw.invoices.confirmation.changeStatus"}/>
                </div>
            </div>

            <hr className="toast-line-info"/>
            <div className="toast-body">
                {!_.isNil(props.invoiceNumber) ? invoiceNumber : invoicesAmount}<br/>
                <b><I18nMessage id="fw.invoices.for.financing.newStatus"/>: {props.newStatus}</b>
            </div>
        </div>
    )
}
