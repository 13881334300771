import * as actionTypes from "./actionTypes";
import _ from "lodash";

function add(toast, toastParams) {
    return {
        type: actionTypes.ADD_NOTIFICATION,
        toast,
        toastParams
    }
}

export function addNotification(toastName, params, redirected) {
    return async (dispatch) => {
        if (redirected) {
            let toastsToRedirect = [];
            const toastsFromSession = window.sessionStorage.getItem("toasts");
            if (!_.isNil(toastsFromSession)) {
                toastsToRedirect = JSON.parse(toastsFromSession);
            }

            toastsToRedirect.push({toastName: toastName, toastParams: params});
            await window.sessionStorage.setItem("toasts", JSON.stringify(toastsToRedirect));
        } else {
            dispatch(add(toastName, params));
        }
    }
}

function remove() {
    return {
        type: actionTypes.REMOVE_NOTIFICATION
    }
}

export function removeNotification() {
    return async (dispatch) => {
        dispatch(remove());
    }
}