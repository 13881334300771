import React from "react";

const typesClassNames = Symbol();
const closeTimeout = Symbol();

export default class Toast extends React.Component {

    constructor(props) {
        super(props);
        this[typesClassNames] = {
            info: "toast-info",
            success: "toast-success",
            danger: "toast-danger",
            warning: "toast-warning"
        };
        this.state = {dismissToast: false};
        this[closeTimeout] = null;
    }

    componentDidMount() {
        if (this.props.dismissTimeout > 0) {
            this[closeTimeout] = setTimeout(() => this.removeNotification(), this.props.dismissTimeout)
        }
    }

    removeNotification = () => {
        if(this[closeTimeout]) {
            clearTimeout(this[closeTimeout]);
        }
        this.setState({dismissToast: true});
        setTimeout(() => {
            this.props.removeNotification(this.props.customKey, this._toast.offsetHeight)
        }, 200);
    };

    render() {

        const {customKey, type, content, closeCrossEnabled} = this.props;

        let stateClass = "toast-slide-in";
        if(this.state.dismissToast) {
            stateClass = "toast-slide-out";
        }
        return (
            <div key={customKey} ref={(toast) => this._toast = toast}
                 className={"abs-toast " + this[typesClassNames][type] + " toast-flex-item " + stateClass}
                 role="toast">
                <div>
                    {closeCrossEnabled &&
                    <button type="button" className="close toast-margin" onClick={this.removeNotification}>
                        <span><i className="abs-icon abs_close"/></span></button>}
                    {content}
                </div>
            </div>

        )
    }
}

