import React from "react";
import PropTypes from "prop-types";
import Percent from "./Percent";

export default class InterestRate extends React.Component {

    render() {
        const {name, percent} = this.props;

        return (
            <span>
                {name}
                {name != undefined && name != null && percent != undefined && percent != null && " + "}
                <Percent value={percent} />
            </span>
        )
    }

    static get propTypes() {
        return {
            name: PropTypes.string,
            percent: PropTypes.number
        }
    }
}
