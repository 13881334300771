import React from "react";
import LoginPage from "./login/containers/LoginPage";
import {Router, Route, browserHistory} from 'react-router';
import {syncHistoryWithStore} from 'react-router-redux';
import {NotificationSystem,application, configureStore, fwUrlUtils} from "assecobs-faktor-web-common/index"
import rootReducer from "./login/reducers/index";
import "assecobs-faktor-web-profile-styles/main.scss";

(async function () {
    const render = await application(false, false);
    const store = await configureStore(rootReducer);
    const history = syncHistoryWithStore(browserHistory, store);

    const content = () => [
        <NotificationSystem key="notification_system"/>,
        <Router history={history} key="router">
            <Route path={fwUrlUtils.getContextUrl("/login")} component={LoginPage}/>
        </Router>
    ];

    render(content, store);
}());

