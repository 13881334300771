import React from "react";
import {I18nMessage} from "assecobs-faktor-web-common/index"
import {injectIntl} from 'react-intl';

class PackageSaveInfoToast extends React.Component {

    render() {
        return (
            <div>
                <div>
                    <div className="toast-head">
                        <I18nMessage id="fw.unsent.toast.package.message"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default injectIntl(PackageSaveInfoToast);
