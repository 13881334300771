import React from "react";
import SendAttachmentToast from "./SendAttachmentToast";
import SendAttachmentErrorToast from "./SendAttachmentErrorToast";
import SendAttachmentsToast from "./SendAttachmentsToast";
import SendAttachmentsErrorToast from "./SendAttachmentsErrorToast";
import InvoiceEditingToast from "./InvoiceEditingToast";
import InvoiceAddingToast from "./InvoiceAddingToast";
import InvoiceAddingInfoToast from "./InvoiceAddingInfoToast";
import PackageSaveInfoToast from "./PackageSaveInfoToast";
import PackageSaveToast from "./PackageSaveToast";
import PackageSendToast from "./PackageSendToast";
import CorrectiveAddingToast from "./CorrectiveAddingToast";
import CorrectiveEditingToast from "./CorrectiveEditingToast";
import OrderReportToast from "./OrderReportToast";
import RequestSentToast from "./RequestSentToast";
import SettingSentToast from "./SettingSentToast";
import InvoiceConfirmationToast from "./InvoiceConfirmationToast";
import InvoiceForFinancingToast from "./InvoiceForFinancingToast";
import PasswordChangedToast from "./PasswordChangedToast";
import CommonToast from "./CommonToast";

import {I18nMessage} from "assecobs-faktor-web-common";

export const SEND_ATTACHMENT_TOAST = (params) => {
    return {
        content: <SendAttachmentToast {...params} />,
        type: "info",
        dismissTimeout: 6000
    }
};

export const SEND_ATTACHMENTS_TOAST = (params) => {
    return {
        content: <SendAttachmentsToast {...params} />,
        type: "info",
        dismissTimeout: 6000
    }
};

export const SEND_ATTACHMENT_ERROR_TOAST = (params) => {
    return {
        content: <SendAttachmentErrorToast {...params} />,
        type: "danger",
        dismissTimeout: 6000
    }
};

export const SEND_ATTACHMENTS_ERROR_TOAST = (params) => {
    return {
        content: <SendAttachmentsErrorToast {...params} />,
        type: "danger",
        dismissTimeout: 10000
    }
};

export const INVOICE_EDITING_TOAST = (params) => {
    return {
        content: <InvoiceEditingToast {...params} />,
        type: "info",
        dismissTimeout: 6000
    }
};

export const INVOICE_ADDING_TOAST = (params) => {
    return {
        content: <InvoiceAddingToast {...params} />,
        type: "info",
        dismissTimeout: 6000
    }
};

export const INVOICE_ADDING_INFO_TOAST = (params) => {
    return {
        content: <InvoiceAddingInfoToast {...params} />,
        type: "info",
        dismissTimeout: 6000
    }
};

export const PACKAGE_SAVE_INFO_TOAST = (params) => {
    return {
        content: <PackageSaveInfoToast {...params} />,
        type: "info",
        dismissTimeout: 6000
    }
};

export const PACKAGE_SAVE_TOAST = (params) => {

    return {
        content: <PackageSaveToast {...params} />,
        type: "info",
        dismissTimeout: 6000
    }
};

export const PACKAGE_SEND_TOAST = (params) => {

    return {
        content: <PackageSendToast {...params} />,
        type: "info",
        dismissTimeout: 6000
    }
};

export const CORRECTIVE_ADDING_TOAST = (params) => {
    return {
        content: <CorrectiveAddingToast {...params} />,
        type: "info",
        dismissTimeout: 6000
    }
};

export const CORRECTIVE_EDITING_TOAST = (params) => {
    return {
        content: <CorrectiveEditingToast {...params} />,
        type: "info",
        dismissTimeout: 6000
    }
};

export const ORDER_REPORT_TOAST = (params) => {
    return {
        content: <OrderReportToast {...params} />,
        type: "info",
        dismissTimeout: 6000
    }
};

export const REQUEST_SENT_TOAST = (params) => {

    return {
        content: <RequestSentToast {...params} />,
        type: "info",
        dismissTimeout: 6000
    }
};

export const INFO_TOAST = (params) => {
    return {
        content: <CommonToast {...params} />,
        type: "info",
        dismissTimeout: 3000
    }
};

export const ERROR_TOAST = (params) => {
    if (_.isEmpty(params)) {
        params = {bodyText: <I18nMessage id="fw.error.body"/>}
    }

    return {
        content: <CommonToast headerTextKey="fw.error.header" {...params} />,
        type: "danger",
        dismissTimeout: 6000
    }
};

export const INVALID_ATTACHMENT_TYPE_TOAST = (params) => {
    return {
        content: <CommonToast headerTextKey="fw.attachment.toast.invalidType"
                              bodyText={<I18nMessage id="fw.attachment.adding.invalidType.info" values={params}/>}/>,
        type: "danger",
        dismissTimeout: 6000
    }
};

export const INVALID_ATTACHMENT_SIZE_TOAST = (params) => {

    return {
        content: <CommonToast headerTextKey="fw.attachment.toast.invalidSize"
                              bodyText={<I18nMessage id="fw.attachment.adding.invalidSize" values={params}/>}/>,
        type: "danger",
        dismissTimeout: 6000
    }
};

export const SETTING_SENT_TOAST = (params) => {
    return {
        content: <SettingSentToast {...params} />,
        type: "info",
        dismissTimeout: 6000
    }
};

export const INVOICE_CONFIRMATION_TOAST = (params) => {
    return {
        content: <InvoiceConfirmationToast {...params}/>,
        type: "info",
        dismissTimeout: 6000
    }
};

export const INVOICE_FOR_FINANCING_TOAST = (params) => {
    return {
        content: <InvoiceForFinancingToast {...params}/>,
        type: "info",
        dismissTimeout: 6000
    }
};

export const PASSWORD_CHANGED_TOAST = () => {
    return {
        content: <PasswordChangedToast/>,
        type: "info",
        dismissTimeout: 6000
    }
};

