import React from "react";
import {I18nMessage, Link, DateOutput, ListMoney} from "assecobs-faktor-web-common/index"

export default class CorrectiveAddingToast extends React.Component {

    render() {

        const {
            invoiceNumber, correctiveNumber, issueDate, correctiveType, invoiceValue, invoiceCurrency,
            packageLink, packageNumber
        } = this.props;

        return (
            <div>
                <div>
                    <div className="toast-head">
                        <I18nMessage id="fw.correctiveInvoice.adding.toast.header"/>
                    </div>
                </div>

                <hr className="toast-line-info"/>
                <div className="toast-body">
                    <I18nMessage id="fw.invoice.no"/>: {invoiceNumber}<br/>
                    <I18nMessage id="fw.correctiveInvoice.adding.correctiveNumber"/>: {correctiveNumber}<br/>
                    <I18nMessage id="fw.invoice.issueDate"/>: <DateOutput>{issueDate}</DateOutput><br/>
                    <I18nMessage id="fw.common.type"/>: {correctiveType}<br/>
                    {invoiceValue && [<I18nMessage id="fw.invoice.toast.value"/>, ":"]} <ListMoney amount={invoiceValue}
                                                                                                   currency={invoiceCurrency}/>
                    <br/>
                    {packageLink && [<I18nMessage id="fw.package"/>, ":"]} {packageLink && <Link
                    id={packageNumber}
                    url={packageLink}
                    text={packageNumber}
                    external/>}
                </div>
            </div>
        )
    }
}